:root {
  --first-color: #040F16;
  --second-color: #FBFBFF;
  --third-color: #E54F6D;
  --fourth-color: #0B4F6C;
  --fifth-color: #0BE7FB;
}

@import "fonts.css";
@import "normalize.css";

html {
  background-color: var(--first-color);
  color: var(--second-color);
  font-family: 'Avenir Next Cyr';
}

body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

p {
  font-family: 'Avenir Next Cyr';
}

.fields>p {
  text-align: center;
}

h1 {
  font-family: 'Avenir Next Cyr';
  font-weight: bold;
  font-size: 35px;
}

h2 {
  font-family: 'Avenir Next Cyr';
  font-weight: bold;
  font-size: 25px;
}

h3 {
  font-family: 'Avenir Next Cyr';
  font-weight: 300;
  font-size: 1.5rem;
}

h4 {
  font-family: 'Avenir Next Cyr';
  font-weight: 400;
  font-size: 20px;
}

h5 {
  font-family: 'Avenir Next Cyr';
  font-weight: 400;
  font-size: 18px;
}

label {
  font-family: 'Avenir Next Cyr';
  color: var(--second-color);
  font-size: 1rem;
}

input {
  box-sizing: border-box;
  font-size: 1.1rem;
}

.field {
  margin-bottom: 15px;
}

.message {
  overflow: auto;
}

.message>p {
  text-align: center;
}

.error {
  color: #f0728b;
}

input.error {
  border-width: 3px !important;
}

.errorbox {
  margin-bottom: 15px;
  background-color: #e54f6d;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
}

.field>input[type="text"],
.field>input[type="password"] {
  width: 100%;
  background-color: var(--fourth-color);
  border: 1px solid var(--third-color);
  border-radius: 5px;
  padding: 15px 10px;
  color: var(--second-color);
  font-family: 'Avenir Next Cyr';
}

.field>input[type="text"]:hover,
.field>input[type="password"]:hover {
  border-color: #f0728b;
  background-color: #0f5f81;
  transition: border-color 0.25s, background-color 0.25s;
}

.buttons {
  padding-bottom: 15px;
}

.buttons>button {
  width: 100%;
  border: 1px solid var(--third-color);
  background-color: var(--third-color);
  color: var(--second-color);
  font-family: 'Avenir Next Cyr';
  font-size: 16px;
  padding: 15px 105px;
  border-radius: 5px;
}

.buttons>button:hover {
  border-color: #f0728b;
  background-color: #f0728b;
  transition: border-color 0.25s, background-color 0.25s;
  box-shadow: 0px 0px 12px -2px var(--third-color);
  cursor: pointer;
}

.field>input[type="text"]:focus,
.field>input[type="password"]:focus {
  outline: none;
}

.field>input[type="text"]::placeholder,
.field>input[type="password"]::placeholder {
  opacity: 1;
}

.check-container {
  display: block;
  position: relative;
  padding: 15px 0px 15px 35px;
  margin: 12px 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-container>input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  opacity: 0.4;
  border: 1px solid var(--third-color);
  border-radius: 2px;
}

.check-container input:checked~.checkmark {
  box-sizing: border-box;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 15px;
  left: 0;
  opacity: 1;
  border: 1px solid var(--third-color);
  border-radius: 2px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-container input:checked~.checkmark:after {
  display: block;
}

.check-container .checkmark:after {
  position: absolute;
  width: 13px;
  height: 13px;
  left: 1px;
  top: 1px;
  background: var(--third-color);
  border-radius: 1px;
}

.footer {
  text-align: center;
  padding-bottom: 15px;
}

.form__under {
  line-height: 1.4;
  text-align: center;
  margin: 15px 0px;
}

.link {
  font-family: 'Avenir Next Cyr';
  font-size: 1rem;
  background: none;
  color: var(--fifth-color);
  border: none;
}

.link:hover {
  color: #78f2fd;
  cursor: pointer;
}

.full {
  position: absolute;
  height: 100%;
  width: 100%;
}

.CENTER_MESSAGE {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  width: 100%;
  position: absolute;
}

.title>h5 {
  font-family: 'Gilroy';
  font-size: 35px;
  color: #FBFBFF;
  text-align: center;
  margin: 20px 0px;
}

.form {
  background-color: var(--fourth-color);
  border-radius: 3px;
  padding: 10px 25px;
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}

.form>.name {
  color: var(--second-color);
  text-align: center;
  overflow: auto;
}

.form__content {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.footer a {
  padding: 50px;
  color: #FBFBFF;
}

.info p {
  margin: 0px;
  color: #FBFBFF;
}

p a {
  color: #FBFBFF;
}

ul {
  padding-left: 20px;
}

li {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

li div {
  display: inline;
}

li div:last-child {
  padding-left: 10px;
  white-space: nowrap;
}

.footer a {
  white-space: nowrap;
}

.positions {
  padding: 5px 0;
  margin: 10px 0;
  border-top: 1px solid #FBFBFF;
  border-bottom: 1px solid #FBFBFF;
}

.total {
  width: 100%;
  text-align: center;
}

.nopay {
  text-align: center;
}

.success {
  text-align: center;
}

@media (max-width: 768px) {
  .footer {
    position: relative;
  }
  .form__content {
    height: 100%;
    position: relative;
  }
  .title {
    position: relative;
  }
  ul {
    padding-left: 10px;
  }
  .form {
    max-width: 500px;
  }
}

@media (max-width: 576px) {
  ul {
    padding-left: 10px;
  }
  .form {
    max-width: 400px;
  }
  .footer a {
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .title>h5 {
    text-align: left;
    padding-left: 100px;
  }
}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {
}