@font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Regular.eot');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-ExtraboldItalic.eot');
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
        url('fonts/Gilroy/Gilroy-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-ExtraboldItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-ExtraboldItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-ExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Black.eot');
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('fonts/Gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Black.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Black.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Light.eot');
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('fonts/Gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Light.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Semibold.eot');
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url('fonts/Gilroy/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Semibold.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Medium.eot');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-MediumItalic.eot');
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url('fonts/Gilroy/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-MediumItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-MediumItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-BlackItalic.eot');
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url('fonts/Gilroy/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-BlackItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-BlackItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-UltraLight.eot');
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url('fonts/Gilroy/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-UltraLight.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-UltraLight.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-RegularItalic.eot');
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url('fonts/Gilroy/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-RegularItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-RegularItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-SemiboldItalic.eot');
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
        url('fonts/Gilroy/Gilroy-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-SemiboldItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-SemiboldItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-HeavyItalic.eot');
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url('fonts/Gilroy/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-HeavyItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-HeavyItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Extrabold.eot');
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url('fonts/Gilroy/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Extrabold.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Extrabold.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-BoldItalic.eot');
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url('fonts/Gilroy/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-BoldItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-BoldItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-UltraLightItalic.eot');
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url('fonts/Gilroy/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-UltraLightItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-UltraLightItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-LightItalic.eot');
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url('fonts/Gilroy/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-LightItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-LightItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Heavy.eot');
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url('fonts/Gilroy/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Heavy.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Heavy.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-Thin.eot');
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url('fonts/Gilroy/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-Thin.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-Thin.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy/Gilroy-ThinItalic.eot');
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url('fonts/Gilroy/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gilroy/Gilroy-ThinItalic.woff2') format('woff2'),
        url('fonts/Gilroy/Gilroy-ThinItalic.woff') format('woff'),
        url('fonts/Gilroy/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-BlackItalic.eot');
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
        url('fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-BoldItalic.eot');
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
        url('fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-LightItalic.eot');
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
        url('fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
        url('fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-ThinItalic.eot');
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
        url('fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'),
        url('fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Black.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Black.woff') format('woff'),
        url('fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-MediumItalic.eot');
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
        url('fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Thin.woff') format('woff'),
        url('fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'),
        url('fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Light.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Italic.eot');
    src: local('Roboto Italic'), local('Roboto-Italic'),
        url('fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Italic.woff') format('woff'),
        url('fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Bold.eot');
    src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
        url('fonts/Avenir-Next/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Bold.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Bold.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-ThinItalic.eot');
    src: local('Avenir Next Cyr Thin Italic'), local('AvenirNextCyr-ThinItalic'),
        url('fonts/Avenir-Next/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-ThinItalic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-ThinItalic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-MediumItalic.eot');
    src: local('Avenir Next Cyr Medium Italic'), local('AvenirNextCyr-MediumItalic'),
        url('fonts/Avenir-Next/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-MediumItalic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-MediumItalic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr Ultra';
    src: url('fonts/Avenir-Next/AvenirNextCyr-UltraLightIt.eot');
    src: local('Avenir Next Cyr Ultra Light Italic'), local('AvenirNextCyr-UltraLightIt'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLightIt.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLightIt.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Light.eot');
    src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
        url('fonts/Avenir-Next/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Light.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Light.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Italic.eot');
    src: local('Avenir Next Cyr Italic'), local('AvenirNextCyr-Italic'),
        url('fonts/Avenir-Next/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Italic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Italic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-BoldItalic.eot');
    src: local('Avenir Next Cyr Bold Italic'), local('AvenirNextCyr-BoldItalic'),
        url('fonts/Avenir-Next/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-BoldItalic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-BoldItalic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Heavy.eot');
    src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
        url('fonts/Avenir-Next/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Heavy.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Heavy.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-HeavyItalic.eot');
    src: local('Avenir Next Cyr Heavy Italic'), local('AvenirNextCyr-HeavyItalic'),
        url('fonts/Avenir-Next/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-HeavyItalic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-HeavyItalic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Thin.eot');
    src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'),
        url('fonts/Avenir-Next/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Thin.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Thin.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Medium.eot');
    src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
        url('fonts/Avenir-Next/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Medium.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Medium.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-DemiItalic.eot');
    src: local('Avenir Next Cyr Demi Italic'), local('AvenirNextCyr-DemiItalic'),
        url('fonts/Avenir-Next/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-DemiItalic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-DemiItalic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-DemiItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Demi.eot');
    src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
        url('fonts/Avenir-Next/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Demi.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Demi.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Demi.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-Regular.eot');
    src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
        url('fonts/Avenir-Next/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-Regular.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-Regular.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('fonts/Avenir-Next/AvenirNextCyr-LightItalic.eot');
    src: local('Avenir Next Cyr Light Italic'), local('AvenirNextCyr-LightItalic'),
        url('fonts/Avenir-Next/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-LightItalic.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-LightItalic.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr Ultra';
    src: url('fonts/Avenir-Next/AvenirNextCyr-UltraLight.eot');
    src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLight.woff2') format('woff2'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLight.woff') format('woff'),
        url('fonts/Avenir-Next/AvenirNextCyr-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

